<template>
    <v-input>
        <v-row align="center">
            <v-col cols="12">
                <v-label>{{ label }}</v-label>
            </v-col>
            <v-col cols="12">
                <v-btn
                    class="mx-2"
                    dark
                    medium
                    color="secondary"
                    @click="setThumbs('up')"
                    :outlined="isThumbsUp"
                >
                    <v-icon dark small> mdi-thumb-up </v-icon>
                    <span
                        v-if="xConfig.upText"
                        class="ml-2"
                        v-text="xConfig.upText"
                    />
                </v-btn>
                <v-btn
                    class="mx-2"
                    medium
                    color="secondary"
                    @click="setThumbs('down')"
                    :outlined="isThumbsDown"
                >
                    <v-icon dark small> mdi-thumb-down </v-icon>
                    <span
                        v-if="xConfig.downText"
                        class="ml-2"
                        v-text="xConfig.downText"
                    />
                </v-btn>
            </v-col>
        </v-row>
        <div v-if="htmlDescription" slot="append">
            <v-tooltip left :open-on-hover="false">
                <template #activator="{ on }">
                    <v-btn
                        icon
                        @click="on.click"
                        @blur="on.blur"
                        retain-focus-on-click
                    >
                        <v-icon>mdi-information</v-icon>
                    </v-btn>
                </template>
                <div class="vjsf-tooltip" v-html="htmlDescription" />
            </v-tooltip>
        </div>
    </v-input>
</template>

<script>
export default {
    props: {
        value: { type: Boolean, default: null },
        options: { type: Object, required: true },
        schema: { type: Object, required: true },
        fullSchema: { type: Object, required: true },
        fullKey: { type: String, required: true },
        label: { type: String, default: '' },
        htmlDescription: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
        required: { type: Boolean, default: false },
        rules: { type: Array, required: true },
        on: { type: Object, required: true },
    },
    data: () => ({ thumbs: '' }),
    methods: {
        input(value) {
            this.on.input(value)
        },
        setThumbs(value) {
            this.thumbs = value
            if (value === 'up') {
                this.on.input(true)
            } else if (value === 'down') {
                this.on.input(false)
            }
        },
    },
    computed: {
        xConfig() {
            return this.schema['x-config']
        },
        isThumbsUp() {
            return this.thumbs === 'up'
        },
        isThumbsDown() {
            return this.thumbs === 'down'
        },
    },
}
</script>

<style scoped>
.v-input .v-label {
    height: auto;
}
</style>
